<template>
    <div id="fsimg" class="fullscreen-img" v-show="fs.display" ref="overlay">
        <img :src="fs.image?.source_url" loading="lazy" :srcset="fs.image?.srcset">
        <a class="close-button" @click="hideOverlay"><FontAwesomeIcon icon="circle-xmark" size="s"/></a>
        <div v-if="fs.caption!==null" class="caption">
            <a class="zoom-button" @click="toggleZoom"><FontAwesomeIcon icon="chevron-up" size="s"/></a>
            <p class="title">{{ fs.caption }}</p>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.fullscreen-img {
    position: absolute;
    z-index: 10;
    width: calc(100% - 1rem);
    height: 100%;
    //display: none;
    background: rgba(0,0,0,0.50);
    backdrop-filter: blur(6px);
    img{
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: auto;
        position: absolute;
        top: 0; left: 0; bottom: 0; right: 0;
        box-shadow: 0 0 1rem 1rem black;
    }
    .caption {
        position: absolute;
        top: calc(100vh - 3rem);
        .title {
            background: #000;
        }
        color: #fff;
        font-weight: bold;
        height: 7rem;
        width: 100%;
        transition: 500ms;
    }
    .title {
        position: absolute;
        display: block;
        width: 100%;
        bottom: 0;
        padding: 0.5rem 0;
    }
    .zoom-button, .close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        display: block;
        font-size: 2rem;
        transition: 500ms;
        color: #fff;
    }
    @media only screen and (min-width: 1024px) {
        .zoom-button {
            display: none;
        }
        .caption {
            top: calc(100vh - 6rem);
        }
        .title {
            padding: 1rem 0;
            opacity: 0;
            transition: 500ms;
        }
        .title:hover {
            opacity: 1;
        }
    }
}
.fullscreen-img.zoomed {
    .caption {
            top: calc(100vh - 6.5rem);

    }
    .zoom-button{
        rotate: 180deg;
    }
}


</style>
<script setup>
import { ref } from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronUp, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


library.add([faChevronUp,faCircleXmark])

import { useFullScreen } from '@/stores/fullscreen_state'

// eslint-disable-next-line no-undef
const overlay = ref(null)
const fs = useFullScreen()

function hideOverlay() {
    fs.display=false
}
function hideOnEscape(e) {
    if (e.key == "Escape" && fs.display == true) {
        hideOverlay()
        //TODO maybe remove the "zoomed" class on #fsimg
    }
}
const zoomed = ref(false)

function toggleZoom (){
  if(!zoomed.value){
    document.getElementById("fsimg").classList.add("zoomed")
    zoomed.value = true
  } else {
    //document.getElementById("app").style.setProperty("grid-template-rows", "3rem 1fr 8rem")
    document.getElementById("fsimg").classList.remove("zoomed")
    zoomed.value = false
  }
}
document.body.addEventListener('keydown', hideOnEscape);
</script>